@import "src/css/variables";

.small-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
    color: $high-white-darkmode;

    .footer-container {
        padding: 24px 8.3vw 48px 8.3vw;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;

        @include tablet {
            flex-direction: row; }

        .disclaimer {
            color:#A7A7A7 {}
            font-size: 12px;
            margin-bottom: 20px;
            @include tablet {
                margin-bottom: unset; }
            a {
                color: inherit;
                text-decoration: none; } } } }
