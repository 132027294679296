@import "src/css/variables";

.navbar {
	position: absolute;
	width: 100%;
	z-index: 99;

	.menu {
		display: flex;
		align-items: center; }

	.navbar-container {
		display: flex;
		justify-content: space-between;
		height: 100px;
		padding: 0 8.3vw;
		margin-top: 30px;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		color: #fff;

		@include phone {
			height: 70px;
			margin-top: 24px; } }

	.navbar-logo {
		position: relative;
		height: 80%;
		margin-left: -5px;

		.logo {
			height: 100%;
			transition: 200ms all ease-in-out;

			&:hover {
				opacity: 0.8;
				transition: 200ms all ease-in-out; } } }

	.language-selector {
		display: flex;
		align-items: center; }

	.lang-item {
		padding-left: 15px; }

	.lang-link {
		padding: 10px; }

	.languageswitcher.select {
		select {
			color: white;
			font-size: 18px;
			option {
				color: $primary; } } } }
