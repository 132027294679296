@import "src/css/variables";

.hidden {
    visibility: 0;
    opacity: 0; }
.show {
    @include animation(slide-in-bottom, 600ms, ease-in-out, 1); }

.CookieConsent {
    background-color: #2222228f;
    color: $high-white-darkmode;
    position: fixed;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    bottom: 0;
    flex-direction: column-reverse;
    text-align: center;
    @include tablet {
        flex-direction: row;
        text-align: left; }
    .content {
        align-items: center;
        flex: 1 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include tablet {
            justify-content: left; }
        span {
            margin-left: 8px; } }
    .buttons {
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
        @include tablet {
            margin-bottom: unset; }
        button {
            @include phone {
                flex: 1 1;
                margin-bottom: 8px; } } }

    .more-info {
        cursor: pointer;
        color: $disabled-white-darkmode;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 100; }

    button {
        padding: 0.5em 2em;
        border-radius: 10px;
        color: $high-white-darkmode;
        margin: 0 5px; }

    button.ManageCookies {
        background-color: #4b4b4b; }
    button.AcceptCookies {
        background-color: $darksecondary-darkmode; } }
