@import "src/css/variables";

body {
	position: absolute;
	overflow-x: hidden;
	overflow-y: auto;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	@include phone {
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none; } } }

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Sarabun', Helvetica, sans-serif;
	letter-spacing: 0.0025em; }

::-moz-selection { //Code for Firefox
	color: #fff;
	background: $secondary; }

::selection {
	color: #fff;
	background: rgba($secondary,0.99); }


img {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-webkit-touch-callout: none;
	max-width: 100%;
	height: auto; }

//prevent text select etc
button, .scrollTop, .material-icons-round {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-webkit-touch-callout: none; }

button { //style reset
	border: 0;
	font-size: 1rem;
	line-height: 1.2;
	white-space: nowrap;
	text-decoration: none;
	cursor: pointer;
	&:focus {
		outline: 0; } }

ul {
	list-style-type: none;
	font-weight: 200; }

a {
	color: inherit;
	text-decoration: inherit;
	font-weight: 200;
	&:hover {
		cursor: pointer; } }


p {
	font-size: calc(14px + 0.15vw);
	//font-size: 16px
	line-height: 1.5em;
	a {
		color: $secondary; } }

h1 {
	font-size: calc(32px + 1.2vw); }

h3 {
	font-size: calc(30px + 1vw); }

h4 {
	font-size: calc(16px + 0.35vw); }

select {
	-moz-appearance: none; //for Firefox
	-webkit-appearance: none; //for Chrome
	// A reset of styles, including removing the default dropdown arrow
	appearance: none;
	// Additional resets for further consistency
	background-color: transparent;
	border: none;
	padding: 0 1em 0 0;
	margin: 0;
	width: 100%;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
	&::-ms-expand { //For IE10
		display: none; } }

.select {
	position: relative;
	display: flex;
	align-items: center;

	&::after {
		font-family: 'Material Icons Round';
		content: "expand_more";
		-webkit-font-feature-settings: 'liga' 1;
		-moz-font-feature-settings: 'liga' 1;
		font-feature-settings: 'liga' 1;
		position: absolute;
		right: 0;
		z-index: 2; } }


/* Works on Firefox */
* {
	scrollbar-width: 8px;
	scrollbar-color: $secondary; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 8px; }

*::-webkit-scrollbar-track {
	background: rgba(0,0,0,0.50); }

*::-webkit-scrollbar-thumb {
	background-color: $secondary;
	@include box-shadow(0, 0, 32px, 0, rgba(0,0,0,0.50), false ); }
