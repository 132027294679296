$primary: #333333;
$secondary: #00baa2;
$body: #666666;

$mobile--horizontal-padding: calc(24px + 1vw);
$high-white-darkmode: rgba(#ffffff, 0.87);
$medium-white-darkmode: rgba(#ffffff, 0.60);
$disabled-white-darkmode: rgba(#ffffff, 0.30);
$secondary-darkmode: #33c8b5;
$darksecondary-darkmode: #009582;

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -ms-border-radius:     $radius;
    border-radius:         $radius; }

@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $size $color {}
        -moz-box-shadow:inset $top $left $blur $size $color {}
        box-shadow:inset $top $left $blur $size $color {} }
    @else {
        -webkit-box-shadow: $top $left $blur $size $color;
        -moz-box-shadow: $top $left $blur $size $color;
        box-shadow: $top $left $blur $size $color; } }

//Media-queries mixin
$phone: 600; //less than
$phone-landscape: 600;
$tablet: 768;
$laptop: 992;
$large: 1200;

/* Extra small devices (phones, 600px and down) */
@mixin phone {
  @media only screen and (max-width: $phone * 1px) {
    @content; } }

/* Small devices (portrait tablets and large phones, 600px and up) */
@mixin phone-landscape {
  @media only screen and (min-width: $phone-landscape * 1px) {
    @content; } }

/* Medium devices (landscape tablets, 768px and up) */
@mixin tablet {
  @media only screen and (min-width: $tablet * 1px) {
    @content; } }

/* Large devices (laptops/desktops, 992px and up) */
@mixin laptop {
  @media only screen and (min-width: $laptop * 1px) {
    @content; } }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@mixin large {
  @media only screen and (min-width: $large * 1px) {
    @content; } }


//Element-ratio mixin
@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding; } }
    @else {
        padding-top: $padding; } }

//Animation
// Keyframes mixin
@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition:    $x;
  -ms-transition:     $x;
  -o-transition:      $x;
  transition:         $x; }

@mixin transform($value) {
  -o-transform: $value;
  -ms-transform: $value;
  -webkit-transform: $value;
  transform: $value; }

@mixin transform-origin ($value) {
  -o-transform-origin: $value;
  -ms-transform-origin: $value;
  -webkit-transform-origin: $value;
  transform-origin: $value; }

@mixin animation($name, $duration, $timing, $type) {
  -o-animation: $name $duration $timing $type;
  -moz-animation: $name $duration $timing $type;
  -webkit-animation: $name $duration $timing $type;
  animation: $name $duration $timing $type; }

@mixin animation-delay($value) {
  -o-animation-delay: $value;
  -moz-animation-delay: $value;
  -webkit-animation-delay: $value;
  animation-delay: $value; }

@mixin keyframes($value) {
  @-webkit-keyframes #{$value} {
    @content; }
  @-moz-keyframes #{$value} {
    @content; }
  @-o-keyframes #{$value} {
    @content; }
  @keyframes #{$value} {
    @content; } }


@mixin parent {

  @each $selector in & {

    $l: length($selector);

    @if ($l == 1) {
      @error "Used parent mixin on a top-level selector"; }
    @else {

      $parent: nth($selector,1);
      @for $i from 2 to $l {
        $parent: append($parent,nth($selector,$i)); }


      @at-root #{$parent} {
        @content; } } } }





