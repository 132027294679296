@import "src/css/variables";

body {
    background: $primary; }

.mobile-only {
    @include phone-landscape {
        display: none; } }

.not-on-mobile {
    @include phone {
        display: none; } }

.early-access {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;

    .top-section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        @include phone {
            min-height: 90vh; }
        .logo {
            display: flex;
            align-items: center;
            //margin: 5% 5% 7% 0%
            flex-wrap: wrap;
            justify-content: center;
            @include phone {
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: start; }
            @include phone-landscape {
                margin-top: 15vh; }
            img {
                max-width: 192px;
                max-height: 192px;
                width: calc(80px + 8vw);
                height: calc(80px + 8vw);
                margin-right: 25px; } } }

    .name {
        color: white;
        font-size: calc(50px + 3vw); }
    .description {
        font-size: calc(18px + 0.15vw);
        padding-left: 1.5%;
        color: rgba(255, 255, 255, 0.5); }

    .early-access-btn {
        display: flex;
        color: white;
        align-items: center;
        justify-content: center;
        font-size: calc(20px + 2vw);
        @include phone-landscape {
            margin-top: 48px; }
        @include phone {
            flex-direction: column;
            margin-top: 3vh; }

        p {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-right: 20px;
            @include phone {
                margin-top: 20px; } }
        span.material-icons-round {
            margin-left: 10px;
            @include animation(1s, left-right, ease-in-out, infinite); }
        span.mobile-only {
            @include animation(1s, float, ease-in-out, infinite); } }

    .floating-elements {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100vw;
        height: 100vh;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        -webkit-touch-callout: none;
        z-index: -1;
        /*+phone
            top: -25%
            left: 0%
            width: 140%
            height: 145%*/

        div {
            position: absolute;
            .floating {
                @include animation(3s, float, ease-in-out, infinite);
                span {
                    $floating-item-size: calc(75px + 1.5vw);
                    position: absolute;
                    height: $floating-item-size;
                    width: $floating-item-size;
                    border-radius: $floating-item-size;
                    font-size: calc(26px + 1.5vw);
                    line-height: $floating-item-size;
                    text-align: center;
                    @include box-shadow(0px, 15px, 30px, -3px, rgba(0,0,0,0.3), false);

                    @include phone {
                        $floating-item-size: calc(50px + 5vw);
                        height: $floating-item-size;
                        width: $floating-item-size;
                        border-radius: $floating-item-size;
                        line-height: $floating-item-size; }

                    @include phone-landscape {
                        $floating-item-size: calc(70px + 1.5vw);
                        height: $floating-item-size;
                        width: $floating-item-size;
                        border-radius: $floating-item-size;
                        line-height: $floating-item-size; } }
                .local_mall {
                    color: #9C27B0;
                    background-color: #E1BEE7;
                    transform: scale(0.8) rotate(-25deg); }
                .spa {
                    color: #41C4AE;
                    background-color: #C6EDE7;
                    transform: scale(0.75) rotate(-15deg); }
                .theaters {
                    color: #EB53C3;
                    background-color: #F9CBED;
                    transform: scale(0.75) rotate(-50deg); }
                .restaurant {
                    color: #1A9AEF;
                    background-color: #BAE1FA;
                    transform: scale(0.9) rotate(28deg); }
                .shopping_cart {
                    color: #FF5533;
                    background-color: #FFCCC1;
                    transform: rotate(-10deg); }
                .home {
                    color: #FF9800;
                    background-color: #FFE0B2;
                    transform: rotate(-5deg); } } }

        /*position*/
        .local_mall {
            top: 10%;
            .floating {
                @include animation-delay(.5s); }
            @include phone {
                top: -2%; } }

        .spa {
            top: -2%;
            right: 17%;
            .floating {
                @include animation-delay(1.3s); }
            @include phone {
                top: 2%;
                right: 3%; } }
        .theaters {
            top: 23%;
            left: 25%;
            @include phone {
                top: 23%;
                left: 6%; } }
        .restaurant {
            top: 32%;
            right: -1%;
            .floating {
                @include animation-delay(1s); }
            @include phone {
                top: 39%;
                right: -5%; } }
        .shopping_cart {
            top: 65%;
            left: 19%;
            .floating {
                @include animation-delay(0.5s); }
            @include phone {
                top: 80%;
                left: -5%; } }
        .home {
            top: 52%;
            right: 22%;
            .floating {
                @include animation-delay(0.7s); }
            @include phone {
                top: 67%;
                right: 7%; } } } }

.footer {
    position: relative;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    opacity: 0.5;
    font-weight: 300;
    .footer-container {
        padding-bottom: 40px; } }
