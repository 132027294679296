@import "src/css/variables";


.manage-cookies {
    width: 100%;
    height: 100%;
    position: relative;
    color: $primary;
    padding: 100px 8.3vw 40px 8.3vw;
    padding-top: 140px;
    h3 {
        font-size: calc(18px + 0.5vw);
        margin: 24px 0; }
    .content-container {
        justify-content: center;
        color: $high-white-darkmode;
        max-width: 1400px;
        @include tablet {
            padding: 100px 8.3vw 40px 8.3vw; } }
    .section {
        margin-top: 40px;
        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px; } }
    .cookie-footer-label {
        margin-top: 40px;
        p {
            font-size: 10px; } }
    .small-footer {
        margin-top: 120px;
        .footer-container {
            padding-left: 0;
            padding-right: 0; } } }
