@import "src/css/variables";

.ReactModal__Body--open {
    overflow-y: hidden; }

.ReactModalPortal {
    position: absolute;
    z-index: 999;
    .ReactModal__Overlay {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        opacity: 0;
        @include tablet {
            align-items: center; } }

    .ReactModal__Overlay--after-open {
        opacity: 1;
        transition: opacity 400ms ease-out; }
    .ReactModal__Overlay--before-close {
        opacity: 0;
        transition: opacity 400ms ease-out; }

    .ReactModal__Content {
        background-color: #ffffff;
        margin: 3vw 5vw;
        border-radius: 10px;
        padding: calc(16px + 2vw);
        max-width: 660px;
        overflow: hidden!important;
        display: flex;
        flex-direction: column;
        @include tablet {
            align-items: center;
            max-height: 90vh; }

        .scrollable {
            height: fit-content;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none; } }
        [data-overflow-viewport] {
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none; } }

        .main-section {
            margin-bottom: 36px;
            text-align: center;
            &>* {
                margin-bottom: 16px; } }
        .section {
            margin-bottom: 24px; }
        h2 {
            font-size: calc(18px + 0.15vw);
            text-align: center; }
        h3 {
            font-size: calc(16px + 0.15vw); }
        h4 {
            font-size: calc(14px + 0.15vw); }
        p {
            color: $body;
            font-weight: 200; }
        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px; } }

    .ReactModal__Content--after-open {
        @include animation(scale-in-center, 0.5s, cubic-bezier(0.250, 0.460, 0.450, 0.940), both); }
    .ReactModal__Content--before-close {
        @include animation(slide-out-top, 400ms, ease-in-out, both); }

    .close-modal {
        position: fixed;
        top: 24px;
        right: 24px;
        background: transparent;
        color: #666666;
        z-index: 9999; }
    .overflow-indicator {
        position: absolute;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        color: #666666;
        &.up {
            top: 0;
            background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            span {
                align-self: flex-start; } }

        &.down {
            bottom: 0;
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            span {
                align-self: flex-end; } } }

    .btn.confirm {
        width: 100%; } }
