@import "src/css/variables";

.index-menu {
    min-width: 250px;
    top: 0;
    height: min-content;
    position: relative;
    margin-bottom: 50px;
    @include tablet {
        position: sticky;
        flex: 1 1;
        max-width: 300px;
        width: 30vw;
        margin-bottom: unset; }

    .index-menu__link:not(:last-of-type) {
        div {
            border-bottom: 1px solid $disabled-white-darkmode; } }
    div {
        padding: 20px;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        &:hover {
            background: $darksecondary-darkmode;
            color: $high-white-darkmode;
            transition: all 200ms ease-in-out; } } }
