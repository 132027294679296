@import "src/css/variables";

.googleplaybutton {
	transition: 200ms opacity ease-in-out;

	&:hover {
		opacity: 0.7;
		transition: 200ms opacity ease-in-out; }
	img {
		max-width: 200px;
		margin-left: -12px;
		vertical-align: bottom; } }

