@import "src/css/variables";

.scrollTop {
    padding: 1em;

    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    z-index: 50;

    align-items: center;
    justify-content: center;

    outline: none;
    border: none;
    cursor: pointer;
    background-color: $primary;
    color: #ffffff;
    @include box-shadow(0px, 18px, 20px, -10px, rgba(0, 0, 0, 0.35), false);
    @include border-radius(100px);
    @include animation(slide-out-bottom, 400ms, ease-in-out, 1);
    opacity: 0;
    visibility: hidden; }

.scrollTop.show {
    @include animation(slide-in-bottom, 400ms, ease-in-out, 1);
    opacity: 1;
    visibility: visible; }
