@import "src/css/variables";

.btn {
	@include border-radius(10px);
	padding: 1em 2.5em 1em 2.5em;
	outline: none;
	border: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	font-weight: 500;
	white-space: nowrap;

	span.material-icons-round {  //Icon on button
		font-size: 1.15em;
		vertical-align: middle;
		position: relative;
		top: -0.1em;
		padding-right: 0.5em; } }

//Button Styles
.btn--primary {
	background-color: $primary;
	color: #ffffff;
	@include box-shadow(0px, 18px, 20px, -10px, rgba(0, 0, 0, 0.35), false);
	margin-top: 24px; }

.button-secondary {
	background-color: $secondary;
	color: #ffffff;
	@include box-shadow(0px, 18px, 20px, -10px, rgba(0, 0, 0, 0.35), false);
	margin-top: 24px;
	&:hover {
		background-color: $secondary; } }

//Button Sizes
.btn--large {
	font-size: 1em;
	@include transition(200ms all ease-in-out);

	@include phone {
		width: 100%; }

	&:hover {
		background-color:    $darksecondary-darkmode;
		@include box-shadow(0px, 0px, 0px, 0px, rgba(0, 0, 0, 0), false);
		@include transition(200ms all ease-in-out); } }

/*.btn
	@include border-radius(10px)
	padding: 1em 2.5em 1em 2.5em
	outline: none
	border: none
	cursor: pointer
	text-transform: uppercase
	letter-spacing: 0.05em
	font-weight: 500

	span.material-icons-round
		font-size: 1.15em
		vertical-align: middle
		position: relative
		top: -0.1em
		padding-right: 0.5em */

/*.btn */

/*.btn--primary
	background-color: $primary
	color: #ffffff
	@include box-shadow(0px, 18px, 20px, -10px, rgba(0, 0, 0, 0.35), false)
	margin-top: 24px */
/* */
/*.btn--large
	font-size: 1em
	@include transition(200ms all ease-in-out) */

/*.btn--large:hover
	background-color: $secondary
	@include box-shadow(0px, 0px, 0px, 0px, rgba(0, 0, 0, 0), false)
	@include transition(200ms all ease-in-out )*/

/*# sourceMappingURL=Button.css.map */
