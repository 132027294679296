@import "src/css/variables";

@include keyframes(float) {
  0% {
    transform:  translateY(0); }
  50% {
    transform:  translateY(-10px); } }

@include keyframes(left-right) {
  0% {
    transform:  translateX(0); }
  50% {
    transform:  translateX(-10px); } }

@include keyframes(slide-in-bottom) {
  0% {
    transform: translateY(1000px);
    opacity: 0;
    visibility: hidden; }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible; } }

@include keyframes(slide-out-bottom) {
  0% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible; }
  100% {
    transform: translateY(1000px);
    opacity: 0;
    visibility: hidden; } }

@include keyframes(slide-out-top) {
  0% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible; }
  100% {
    transform: translateY(-1000px);
    opacity: 0;
    visibility: hidden; } }

@include keyframes(scale-in-center) {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@include keyframes(scale-out-center) {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 1; } }
