@import "src/css/variables";

.scrollTop {
    background-color: #4B4B4B!important; }

.privacy-policy-container {
    display: flex;
    padding: 140px 8.3vw 100px 8.3vw;
    justify-content: center;
    color: $high-white-darkmode;
    flex-direction: column;
    max-width: 1400px;
    margin: 0 auto;
    @include tablet {
        flex-direction: row; }
    ul {
        list-style-type: initial;
        margin-left: 24px;
        color: $medium-white-darkmode;
        font-weight: 200;
        li {
            margin: 8px 0; } }

    .content-section {
        flex: 1 1;
        @include tablet {
            padding: 0 60px 40px 60px; }
        p {
            margin-bottom: 12px;
            color: $medium-white-darkmode;
            font-weight: 200;
            a {
                color: $secondary-darkmode; } }
        h1, h2 {
            margin-bottom: 16px; }
        div {
            margin-bottom: 32px; } } }

.privacy-policy {
    .privacy__link {
        display: none;
        visibility: hidden; } }
